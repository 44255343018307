export interface Price {
  id: string
  stripe_price_id: string
  active: boolean
  currency: string
  unit_amount: number
  type: string
  recurring: { interval: string }
  metadata: {
    name: string
    description: string
    level?: string
  }
}

export interface MarketingFeature {
  name: string
}

export interface Product {
  id: string
  name: string
  description: string
  images: string[]
  priceMaxLevel?: string | number
  marketing_features: { name: string }[]
  metadata: {
    category: string
    resources?: string
  }
  prices: Price[]
  active: boolean
  isSubscribed: boolean
  capabilities?: { keyword: string; description: string }[]
  product_prices?: any[]
  product_metadata: {
    category: string
    resources?: string
    setup_price_id?: string
    is_superwise?: string
    description_1?: string
    description_2?: string
    attributes?: string
  }
  default_price: string
}

interface ProductMetadata {
  key: string
  value: string
}

export function mapStripeDataToProducts(stripeData: any): Product[] {
  const productsMap = new Map<string, Product>()

  stripeData.data.forEach((productData: any) => {
    // Extract price information
    const priceData = productData.product_prices
    const prices: Price[] = []
    priceData?.forEach((price: any) => {
      const priceObj: Price = {
        id: price.id,
        stripe_price_id: price.stripe_price_id,
        active: price.active,
        currency: price.currency,
        unit_amount: price.unit_amount,
        type: price.type,
        recurring: price.recurring,
        metadata: price.metadata || { name: "", description: "" },
      }
      prices.push(priceObj)
    })

    // Extract metadata
    const productMetadata = productData.product_metadata || []
    const metadata = {
      category: productData.category || "",
      resources: productMetadata.find(
        (m: ProductMetadata) => m.key === "resources"
      )?.value,
    }

    // Map product data
    const productId = productData.stripe_product_id
    if (!productsMap.has(productId)) {
      const product: Product = {
        id: productId,
        name: productData.name,
        description: productData.description,
        images: productData.images,
        marketing_features: productData.marketing_features,
        metadata: metadata,
        prices: prices.filter((price: any) => price.recurring),
        priceMaxLevel: productData.priceMaxLevel,
        active: productData.active,
        isSubscribed: false,
        capabilities: productData.capabilities,
        product_prices: productData.product_prices,
        product_metadata: {
          category: productData.category || "",
          resources: productMetadata.find(
            (m: ProductMetadata) => m.key === "resources"
          )?.value,
          setup_price_id: productMetadata.find(
            (m: ProductMetadata) => m.key === "setup_price_id"
          )?.value,
          is_superwise: productMetadata.find(
            (m: ProductMetadata) => m.key === "is_superwise"
          )?.value,
          description_1: productMetadata.find(
            (m: ProductMetadata) => m.key === "description_1"
          )?.value,
          description_2: productMetadata.find(
            (m: ProductMetadata) => m.key === "description_2"
          )?.value,
          attributes: productMetadata.find(
            (m: ProductMetadata) => m.key === "attributes"
          )?.value,
        },
        default_price: productData.default_price || "",
      }
      productsMap.set(productId, product)
    }
  })

  return Array.from(productsMap.values())
}

export function mapStripeDataToProduct(productData: any): Product[] {
  const productsMap = new Map<string, Product>()
  // Extract price information
  const priceData = productData.product_prices
  const prices: Price[] = []
  priceData?.forEach((price: any) => {
    const priceObj: Price = {
      id: price.id,
      stripe_price_id: price.stripe_price_id,
      active: price.active,
      currency: price.currency,
      unit_amount: price.unit_amount,
      type: price.type,
      recurring: price.recurring,
      metadata: price.metadata || { name: "", description: "" },
    }
    prices.push(priceObj)
  })

  // Extract metadata
  const productMetadata = productData.product_metadata || []
  const metadata = {
    category: productData.category || "",
    resources: productMetadata.find(
      (m: ProductMetadata) => m.key === "resources"
    )?.value,
  }

  // Map product data
  const productId = productData.stripe_product_id
  if (!productsMap.has(productId)) {
    const product: Product = {
      id: productId,
      name: productData.name,
      description: productData.description,
      images: productData.images,
      priceMaxLevel: productData.priceMaxLevel,
      marketing_features: productData.marketing_features,
      metadata: metadata,
      prices: prices.filter((price: any) => price.recurring),
      active: productData.active,
      isSubscribed: productData.isSubscribed,
      capabilities: productData.capabilities,
      product_prices: productData.product_prices,
      product_metadata: {
        category: productData.category || "",
        resources: productMetadata.find(
          (m: ProductMetadata) => m.key === "resources"
        )?.value,
        setup_price_id: productMetadata.find(
          (m: ProductMetadata) => m.key === "setup_price_id"
        )?.value,
        is_superwise: productMetadata.find(
          (m: ProductMetadata) => m.key === "is_superwise"
        )?.value,
        description_1: productMetadata.find(
          (m: ProductMetadata) => m.key === "description_1"
        )?.value,
        description_2: productMetadata.find(
          (m: ProductMetadata) => m.key === "description_2"
        )?.value,
        attributes: productMetadata.find(
          (m: ProductMetadata) => m.key === "attributes"
        )?.value,
      },
      default_price: productData.default_price || "",
    }
    productsMap.set(productId, product)
  }
  return Array.from(productsMap.values())
}
